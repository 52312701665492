import { ReactElement, createContext, useContext, useState } from "react";
import AuthWrapper from "src/components/authentication";
import { Route, getRoute, routerReplace } from "src/common/routing";
import SetupPages from "src/components/routes/Setup/common/constants/SetupPages";
import Head from "next/head";
import { AnimatePresence } from "framer-motion";
import {
  Box,
  ColorPreset,
  Container,
  Space,
  XYGrid,
} from "@gocardless/flux-react";
import Header from "src/components/routes/Setup/common/components/Header";
import Footer from "src/components/routes/Setup/common/components/Footer";
import ThreatMetrixProfiler from "src/components/ThreatMetrixProfiler";
import SetupPageLabels from "src/components/routes/Setup/common/constants/SetupPageLabels";
import OptimizelyExperiments from "src/components/third-parties/Optimizely/OptimizelyExperiments";
import {
  useRouting,
  isMigratedRoute,
  ONBOARDING_MIGRATION_KEY,
} from "src/components/routes/Setup/routing/useRouting";
import { Redirect } from "src/components/routing";
import SidebarComponent from "src/components/routes/Setup/common/components/Sidebar";
import { OptimizelyContext } from "@optimizely/react-sdk";
import { useOrganisation } from "src/queries/organisation";
import { useLingui } from "@lingui/react";

import { OptimizelyFlag } from "../third-parties/Optimizely/constants";

export interface SetupPageLayoutProps {
  setupPage: SetupPages;
  children: React.ReactNode;
}

export const getLayout = (setupPage: SetupPages) => (page: ReactElement) => (
  <AuthWrapper>
    <SetupPageLayout setupPage={setupPage}>{page}</SetupPageLayout>
  </AuthWrapper>
);

export const NavRouteContext = createContext<{
  backRoute: Route;
  nextRoute: Route;
  skipDefaultRoute: boolean;
  setSkipDefaultRoute: (skip: boolean) => void;
}>({
  backRoute: Route.PackageSelection,
  nextRoute: Route.AccountStatus,
  skipDefaultRoute: false,
  setSkipDefaultRoute: () => {},
});

const SetupPageContent = ({ children, setupPage }: SetupPageLayoutProps) => {
  const [sideBarVisible, setSideBarVisible] = useState<boolean>(false);
  const [skipDefaultRoute, setSkipDefaultRoute] = useState<boolean>(false);
  const { i18n } = useLingui();
  const {
    loaded,
    defaultRoute,
    backRoute = Route.PackageSelection,
    nextRoute = Route.AccountStatus,
    setupSteps = [],
  } = useRouting(setupPage, skipDefaultRoute);
  if (setupPage === SetupPages.Index && loaded && defaultRoute) {
    routerReplace({ route: defaultRoute });
  }

  return (
    <>
      <Head>
        <title>{SetupPageLabels(i18n, setupPage)} • GoCardless</title>
      </Head>
      <ThreatMetrixProfiler />
      <XYGrid templateColumns="max-content auto">
        <SidebarComponent
          sideBarVisible={sideBarVisible}
          setupPage={setupPage}
          setupSteps={setupSteps}
          closeSidebar={() => setSideBarVisible(false)}
        />
        <Box bg={ColorPreset.BackgroundLight_02}>
          <Header
            setupPage={setupPage}
            setupSteps={setupSteps}
            openSideBar={() => setSideBarVisible(true)}
          />
          <Container contentGutters={[2, null, 0]}>
            <AnimatePresence
              exitBeforeEnter
              initial={false}
              onExitComplete={() => window.scrollTo(0, 0)}
            >
              {loaded && (
                <NavRouteContext.Provider
                  value={{
                    backRoute,
                    nextRoute,
                    skipDefaultRoute,
                    setSkipDefaultRoute,
                  }}
                >
                  {children}
                </NavRouteContext.Provider>
              )}
              {!loaded && <Space v={48} />}
            </AnimatePresence>
            <Footer />
          </Container>
        </Box>
      </XYGrid>
    </>
  );
};

const SetupPageLayout: React.FC<SetupPageLayoutProps> = ({
  children,
  setupPage,
}) => {
  const { optimizely } = useContext(OptimizelyContext);
  const organisation = useOrganisation();

  const content = (
    <SetupPageContent setupPage={setupPage}>{children}</SetupPageContent>
  );

  if (isMigratedRoute()) {
    if (
      window.location.pathname === getRoute(Route.PackageSelection) &&
      organisation?.pricing_version === 3
    ) {
      return content;
    }
    if (optimizely) {
      const { variationKey } = optimizely.decide(ONBOARDING_MIGRATION_KEY);
      if (variationKey === "on") return content;
    }
  }

  return (
    <OptimizelyExperiments
      defaultNode={<Redirect route={Route.Onboarding} />}
      experiments={[OptimizelyFlag.SETUP_FLOW_EXPERIMENT]}
    >
      {content}
    </OptimizelyExperiments>
  );
};

export default SetupPageLayout;
