import { Field, Label, Input } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

import { Field as Config } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

import { error, presenceCheck } from "./helpers";

const CharityNameField: React.FC = () => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
  } = useFormContext<BusinessDetailsConfig>();
  return (
    <Field>
      <Label htmlFor="charity_name">
        <Trans id="charity_name">Official charity name</Trans>
      </Label>
      <Input
        id="charity_name"
        {...register(
          "charity_name",
          presenceCheck(
            i18n._(
              t({
                id: "setup.business-details.charity-name-error",
                message: "Charity name is required",
              })
            )
          )
        )}
      />
      {error(errors, "charity_name")}
    </Field>
  );
};

export const config: Config = {
  name: "charity_name",
  required: true,
  displayName: "Charity Name",
  component: CharityNameField,
};

export default CharityNameField;
