import { useRegisteredCompanyList } from "@gocardless/api/dashboard/registered-company";

export function useRegisteredCompanies(
  registeredCompaniesQuery: string,
  geo: string | undefined
): {
  registeredCompanies: Array<{
    legalName: string;
    companyNumber: string;
    geo: string;
  }>;
  registeredCompaniesComplete: boolean;
} {
  const { data: registeredCompaniesData, error } = useRegisteredCompanyList(
    {
      country_code: geo ?? "",
      query: registeredCompaniesQuery,
    },
    !!registeredCompaniesQuery && !!geo
  );

  let registeredCompanies: Array<{
    legalName: string;
    companyNumber: string;
    geo: string;
  }> = [];

  if (registeredCompaniesData !== undefined) {
    registeredCompanies = (
      registeredCompaniesData as {
        registered_companies: Array<{
          name: string;
          number: string;
          country_code: string;
          id: string;
        }>;
      }
    ).registered_companies.map((registeredCompany) => ({
      companyNumber: registeredCompany.number || registeredCompany.id,
      legalName: registeredCompany.name,
      geo: registeredCompany.country_code,
    }));
  }

  return {
    registeredCompanies,
    registeredCompaniesComplete: !!registeredCompaniesData || !!error,
  };
}
