import { Route, getRoute, routerReplace } from "src/common/routing";
import SetupPages from "src/components/routes/Setup/common/constants/SetupPages";
import { usePersonActingOnBehalfOf } from "src/components/routes/Setup/person-acting-on-behalf-of/usePersonActingOnBehalfOf";
import { OptimizelyFlag } from "src/components/third-parties/Optimizely/constants";
import { useOptimizelyVariation } from "src/components/third-parties/Optimizely/useOptimizelyVariation";
import { useAchUpliftDetails } from "src/components/routes/Setup/ach-uplift-details/useAchUpliftDetails";

import { useAboutYou } from "../about-you/useAboutYou";
import { useAccountStatus } from "../account-status/useAccountStatus";
import { useBankDetailsSelect } from "../bank-details-select/useBankDetailsSelect";
import { useBankDetails } from "../bank-details/hooks/useBankDetails";
import { useBankStatementName } from "../bank-statement-name/useBankStatementName";
import { useSetupBillingDetails } from "../billing-details/useSetupBillingDetails";
import { useBusinessCategory } from "../business-category/useBusinessCategory";
import { useBusinessDetails } from "../business-details/useBusinessDetails";
import { useBusinessDirectors } from "../business-directors/useBusinessDirectors";
import { useBusinessOwners } from "../business-owners/useBusinessOwners";
import { useContacts } from "../contact-details/useContacts";
import { useMoreBusinessDetails } from "../more-business-details/useMoreBusinessDetails";
import { usePackageSelection } from "../package-selection/usePackageSelection/usePackageSelection";
import { usePaymentVolumes } from "../payment-volumes/usePaymentVolumes";
import { useTrustOwners } from "../trust-owners/useTrustOwners";
import { useTrusteeDetails } from "../trustee-details/useTrusteeDetails";
import { useVerifyBankDocument } from "../verify-bank-document/useVerifyBankDocument";
import { useVerifyBank } from "../verify-bank/useVerifyBank";

import {
  getActiveSetupPages,
  getBackAndNextRoute,
  getSetupSteps,
  getStep,
} from "./helpers";
import { SetupData, SetupSteps } from "./types";

interface UseRouting {
  loaded: boolean;
  defaultRoute?: Route;
  backRoute?: Route;
  nextRoute?: Route;
  setupSteps?: SetupSteps;
}

const migratedRoutes = [Route.PackageSelection];
export const ONBOARDING_MIGRATION_KEY = "ed_to_md_onboarding_migrate";
export const isMigratedRoute = () => {
  const currentRoute = window.location.pathname;
  return !!migratedRoutes.find((r) => getRoute(r) === currentRoute);
};

/**
 * Page Ordering
 *
 * Select Package
 * Business Details
 * About You [ Individual / Sole trader ]
 * More Business Details
 * Contact Details
 * Business Category
 * Payment Volumes
 * Company About You
 * Business Directors [ Limited ]
 * Business Owners [ Limited ]
 * Bank Account Verification  - automatic verification
 * Bank Details Select
 * Add Your Bank Account
 * Verify Payout Account
 * Bank Statement Name
 * Billing Details Direct Debit
 * Verification Status
 */
export const useRouting = (
  setupPage: SetupPages = SetupPages.Index,
  skipDefaultRoute = false
): UseRouting => {
  const packageSelection = usePackageSelection();
  const businessDetails = useBusinessDetails();
  const aboutYou = useAboutYou();
  const moreBusinessDetails = useMoreBusinessDetails();
  const contacts = useContacts();
  const category = useBusinessCategory();
  const paymentVolumes = usePaymentVolumes();
  const personActingOnBehalfOf = usePersonActingOnBehalfOf();
  const trusteeDetails = useTrusteeDetails();
  const trustOwners = useTrustOwners();
  const directors = useBusinessDirectors();
  const owners = useBusinessOwners();
  const achUpliftDetails = useAchUpliftDetails();
  const bankDetailsSelect = useBankDetailsSelect();
  const bankDetails = useBankDetails();
  const verifyBank = useVerifyBank();
  const verifyBankDocument = useVerifyBankDocument();
  const bankStatementName = useBankStatementName();
  const billingDetails = useSetupBillingDetails();
  const accountStatus = useAccountStatus();

  const { isVariationOn: isVerifyBankDocumentFlagOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.NA_MANUAL_UPLOAD_BANK_DOCUMENTS,
  });
  const { isVariationOn: isPaymentVolumesFlagOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_SETUP_PAYMENT_VOLUMES,
  });
  // Feature Flags
  const experiments = [
    {
      page: SetupPages.VerifyBankDocument,
      experimentEnabled: isVerifyBankDocumentFlagOn,
    },
    {
      page: SetupPages.PaymentVolumes,
      experimentEnabled: isPaymentVolumesFlagOn,
    },
  ];

  // The order of items in this array is extremely important, do not change.
  const allSetupRoutes: SetupData = [
    { page: SetupPages.PackageSelection, pageData: packageSelection },
    { page: SetupPages.BusinessDetails, pageData: businessDetails },
    { page: SetupPages.AboutYou, pageData: aboutYou },
    { page: SetupPages.MoreBusinessDetails, pageData: moreBusinessDetails },
    { page: SetupPages.ContactDetails, pageData: contacts },
    { page: SetupPages.BusinessCategory, pageData: category },
    { page: SetupPages.PaymentVolumes, pageData: paymentVolumes },
    {
      page: SetupPages.PersonActingOnBehalfOf,
      pageData: personActingOnBehalfOf,
    },
    { page: SetupPages.TrusteeDetails, pageData: trusteeDetails },
    { page: SetupPages.BusinessDirectors, pageData: directors },
    { page: SetupPages.TrustOwners, pageData: trustOwners },
    { page: SetupPages.BusinessOwners, pageData: owners },
    { page: SetupPages.AchUpliftDetails, pageData: achUpliftDetails },
    { page: SetupPages.BankDetailsSelect, pageData: bankDetailsSelect },
    { page: SetupPages.BankDetails, pageData: bankDetails },
    { page: SetupPages.VerifyBank, pageData: verifyBank },
    { page: SetupPages.BankStatementName, pageData: bankStatementName },
    { page: SetupPages.BillingDetails, pageData: billingDetails },
    { page: SetupPages.VerifyBankDocument, pageData: verifyBankDocument },
    { page: SetupPages.AccountStatus, pageData: accountStatus },
  ];

  const activeSetupRoutes = getActiveSetupPages(allSetupRoutes, experiments);

  // If we are still waiting on data do nothing!
  if (!activeSetupRoutes.every(({ pageData }) => pageData.loaded)) {
    return { loaded: false };
  }

  // What page should I be on?
  const defaultData = activeSetupRoutes.find(
    ({ pageData }) => !pageData.completed
  );
  const defaultRoute = defaultData?.pageData?.route || Route.AccountStatus;
  const setupSteps = getSetupSteps(activeSetupRoutes, defaultRoute);

  const currentStep = getStep(setupSteps, setupPage);
  const defaultStep = getStep(setupSteps, setupPage, defaultData?.page);

  // Redirect to default step if current step not present or after the default step
  // This means the current step is not required or a previous step is not completed.
  if (
    setupPage !== SetupPages.Index &&
    (currentStep === -1 || defaultStep < currentStep) &&
    !skipDefaultRoute
  ) {
    routerReplace({ route: defaultRoute });
    return { loaded: false };
  }

  const { backRoute, nextRoute } = getBackAndNextRoute(setupSteps, currentStep);

  return {
    loaded: true,
    defaultRoute,
    backRoute,
    nextRoute,
    setupSteps,
  };
};
