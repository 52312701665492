import {
  Box,
  Button,
  ButtonLayout,
  ColorPreset,
  Input,
  InputProps,
  useTheme,
  ButtonVariant,
} from "@gocardless/flux-react";
import { ForwardedRef, forwardRef, Ref, useState } from "react";

type SearchInputProps<T> = {
  searchResults: (T & { key: string })[];
  onSearchResultSelected: (searchResult: T) => void;
  ref?: Ref<HTMLInputElement>;
  renderSearchResult: (searchResult: T & { key: string }) => JSX.Element;
  renderEnterManually?: () => JSX.Element;
  searchComplete: boolean;
  inputFocusedStyles?: { [key: string]: string | number };
} & InputProps;

const _SearchInput = <T,>(
  {
    searchResults,
    onSearchResultSelected,
    renderSearchResult,
    renderEnterManually,
    searchComplete,
    inputFocusedStyles,
    ...props
  }: SearchInputProps<T>,
  ref: ForwardedRef<HTMLInputElement>
): JSX.Element => {
  const { theme } = useTheme();
  const [inputFocused, setInputFocused] = useState(false);

  return (
    <>
      <div
        css={{
          marginBottom: "0 !important",
          div: searchComplete && {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        }}
      >
        <Input
          type="text"
          {...props}
          ref={ref}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
        />
      </div>
      {searchComplete && (
        <Box
          css={{
            borderBottomLeftRadius: theme.tokens.borderRadiusBase,
            borderBottomRightRadius: theme.tokens.borderRadiusBase,
            borderColor: theme.color(ColorPreset.BorderOnLight_03),
            borderStyle: "solid",
            borderWidth: "thin",
            backgroundColor: theme.color(ColorPreset.BackgroundLight_01),
            overflow: "hidden",
            ...(inputFocused && inputFocusedStyles),
          }}
        >
          {searchResults.length > 0 ? (
            <Box css={{ maxHeight: "230px", overflow: "auto" }}>
              {searchResults.map((searchResult) => (
                <Button
                  onClick={() => {
                    onSearchResultSelected(searchResult);
                  }}
                  variant={ButtonVariant.Inline}
                  layout={ButtonLayout.Full}
                  key={searchResult.key}
                  css={{
                    "&:hover": {
                      backgroundColor: theme.color(
                        ColorPreset.BackgroundLight_03
                      ),
                    },
                  }}
                >
                  {renderSearchResult(searchResult)}
                </Button>
              ))}
            </Box>
          ) : (
            <Box gutterH={0.75} gutterV={0.75}>
              No results found
            </Box>
          )}
          {renderEnterManually ? (
            <Box
              css={{
                borderColor: theme.color(ColorPreset.BorderOnLight_03),
                borderTopStyle: "solid",
                borderWidth: "thin",
              }}
              gutterH={0.75}
              gutterV={0.75}
            >
              {renderEnterManually()}
            </Box>
          ) : null}
        </Box>
      )}
    </>
  );
};

const SearchInput = forwardRef(_SearchInput) as <T>(
  props: SearchInputProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof _SearchInput>;

export default SearchInput;
