import { CharityType, CreditorType } from "@gocardless/api/dashboard/types";
import { Route } from "src/common/routing";
import { CreditorDetailUpdateRequestBody } from "@gocardless/api/dashboard/creditor-detail";
import {
  aboutYouBuilder,
  parseAboutYou,
} from "src/components/routes/Setup/common/builders/about-you";
import {
  useUserShowSelf,
  useUserUpdateSelf,
} from "@gocardless/api/dashboard/user";
import { usePaobVerification } from "src/components/routes/Setup/common/hooks/usePaobVerification";
import { useIsImpersonation } from "src/queries/user";

import { useComplete } from "../common/validators/useComplete";
import { useSetupData } from "../common/hooks/useSetupData";
import { AddressConfig } from "../common/components/Address/AddressSearch";
import { UseSetupPage, UseSetupPageCallbacks } from "../routing/types";
import { SetupPages } from "../common/config/types";
import { removeEmptyObjProperties } from "../common/helpers/removeEmptyObjProperties";

export interface UseAboutYou extends UseSetupPage {
  aboutYou: Partial<AboutYouConfig>;
  countryCode: string;
  submitAboutYou: (data: AboutYouConfig) => void;
  shouldUpdateUserAmlEntity: boolean;
}

export type IndividualPersonType = {
  given_name: string;
  family_name: string;
  date_of_birth: string;
  place_of_birth?: string | null;
} & AddressConfig &
  Required<
    Pick<AddressConfig, "street" | "city" | "postal_code" | "country_code">
  >;
export interface AboutYouConfig {
  individual_number?: string;
  individual_number_type?: string;
  person: IndividualPersonType;
  account_holder_is_trustee?: boolean;
  creditor_type?: CreditorType | null;
  charity_type?: CharityType;
}

export function useAboutYou({
  onSuccess = () => {},
  onError = () => {},
}: UseSetupPageCallbacks = {}): UseAboutYou {
  const { creditorDetails } = useSetupData();
  const {
    loaded: paobVerificationLoaded,
    gcSasVerificationEnabled,
    isPrimaryUser,
    userAmlEntity,
    updateUserAmlEntity,
  } = usePaobVerification({ onSuccess, onError });
  const { data: userData } = useUserShowSelf();
  const isImpersonation = useIsImpersonation();
  const { isComplete } = useComplete(SetupPages.ABOUT_YOU);
  const trustees = creditorDetails?.detail?.trustees || [];
  const countryCode = creditorDetails?.detail?.country_code ?? "";
  const aboutYou = parseAboutYou(
    creditorDetails,
    isImpersonation ? undefined : userData?.users,
    countryCode
  );
  const creditorType = creditorDetails?.creditor_type;
  const shouldUpdateUserAmlEntity =
    (creditorType === CreditorType.Individual ||
      creditorType === CreditorType.Charity ||
      creditorType === CreditorType.Partnership) &&
    gcSasVerificationEnabled &&
    isPrimaryUser;
  const { updateCreditorDetail } = useSetupData(
    shouldUpdateUserAmlEntity ? () => {} : onSuccess,
    onError
  );

  const [updateUser] = useUserUpdateSelf();

  const submitAboutYou = (data: AboutYouConfig) => {
    if (!creditorType) return;

    const put: CreditorDetailUpdateRequestBody = {
      creditor_type: creditorType,
      detail: aboutYouBuilder(creditorType)(data, countryCode, trustees),
    };

    updateCreditorDetail(removeEmptyObjProperties(put)).then(() => {
      if (shouldUpdateUserAmlEntity) {
        updateUserAmlEntity({
          given_name: data.person.given_name,
          family_name: data.person.family_name,
          date_of_birth: data.person.date_of_birth,
          place_of_birth: data.person.place_of_birth,
          country_code: countryCode,
        });
      }
    });

    if (!userData?.users?.family_name && !userData?.users?.given_name) {
      updateUser({
        family_name: data.person.family_name,
        given_name: data.person.given_name,
      });
    }
  };

  return {
    loaded: !!creditorDetails && !!userData && paobVerificationLoaded,
    skip:
      creditorType !== CreditorType.Individual &&
      creditorType !== CreditorType.Charity,
    completed:
      isComplete({
        creditor_type: creditorType,
        geo: countryCode,
        ...aboutYou.person,
      }) &&
      (!shouldUpdateUserAmlEntity || !!userAmlEntity?.place_of_birth),
    route: Route.AboutYou,
    aboutYou,
    countryCode,
    submitAboutYou,
    shouldUpdateUserAmlEntity,
  };
}
