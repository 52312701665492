import {
  Banner,
  BannerStatus,
  BannerVariant,
  Field,
  FormFieldStatus,
  Hint,
  Label,
  Option,
  Space,
  Select,
} from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { get } from "lodash";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  merchantCategories,
  prohibitedMerchantCategories,
  merchantCategoriesCodeMapping,
} from "src/components/routes/Setup/business-category/constants";

import { Field as Config } from "../config/types";
import { BusinessCategoryConfig } from "../../business-category/useBusinessCategory";

// import { error } from "./helpers";

const CategoryCodeField = () => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<BusinessCategoryConfig>();

  const ind = watch("industry");
  const categories = ind
    ? get(merchantCategories, ind).map((k: string) => ({
        code: k,
        category: get(merchantCategoriesCodeMapping(), k),
      }))
    : [];

  return (
    <Field>
      <Label htmlFor="merchantCategoryCode">
        <Trans id="setup.business-category.category">Category</Trans>
      </Label>
      <Select
        {...register("merchantCategoryCode", {
          required: i18n._(
            t({
              id: "setup.business-category.choose-a-category",
              message: "Please choose a category",
            })
          ),
          validate: (v) =>
            v && prohibitedMerchantCategories.includes(v)
              ? i18n._(
                  t({
                    id: "setup.business-category.business-type-not-supported",
                    message: "We are unable to serve this type of business",
                  })
                )
              : true,
        })}
        id="merchantCategoryCode"
      >
        <Option value="">
          {i18n._(
            t({
              id: "setup.business-category.choose-category",
              message: "Choose a category",
            })
          )}
        </Option>
        {categories.map((v: string, i: string) => (
          <Option key={i} value={get(v, "code")}>
            {get(v, "category", "")}
          </Option>
        ))}
      </Select>
      {errors.merchantCategoryCode?.type === "required" && (
        <Hint status={FormFieldStatus.Danger}>
          <Trans id="setup.business-category.choose-a-category">
            Please choose a category
          </Trans>
        </Hint>
      )}
      {errors.merchantCategoryCode?.type === "validate" && (
        <>
          <Space v={2} />
          <Banner
            variant={BannerVariant.Light}
            status={BannerStatus.Warning}
            title={i18n._(
              t({
                id: "setup.business-category.business-type-not-supported",
                message: "We are unable to serve this type of business",
              })
            )}
          >
            <Trans id="setup.business-category.business-type-not-supported-body">
              Unfortunately we are unable to serve this type of business in your
              region. Working through partner banks around the world, we are
              subject to restrictions.
            </Trans>
          </Banner>
        </>
      )}
    </Field>
  );
};

export const config: Config = {
  name: "merchantCategoryCode",
  required: true,
  displayName: "Category Code",
  component: CategoryCodeField,
};

export default CategoryCodeField;
