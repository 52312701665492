import {
  Field,
  Label,
  Option,
  Tooltip,
  Box,
  Space,
  Select,
  Text,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useFormContext } from "react-hook-form";
import { countryCodeToName } from "src/common/country";
import { useSupportedCountries } from "src/common/hooks/useSupportedCountries";
import { TrackingEvent } from "src/common/trackingEvents";
import { useSegment } from "src/components/third-parties/Segment/useSegment";
import { useLingui } from "@lingui/react";

import { Field as Config } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

const CountryField: React.FC = () => {
  const { supportedCountries } = useSupportedCountries();
  const { register } = useFormContext<BusinessDetailsConfig>();
  const { i18n } = useLingui();

  const options = supportedCountries.map((code) => (
    <Option key={code} value={code}>
      {countryCodeToName(i18n)[code]}
    </Option>
  ));

  const { sendEvent } = useSegment();

  return options.length ? (
    <>
      <Field>
        <Box layout="flex">
          <Label htmlFor="geo" css={{ paddingTop: "3px" }}>
            <Trans id="Country">Country</Trans>
          </Label>
          <Text
            onMouseEnter={() =>
              sendEvent(
                TrackingEvent.VERIFICATION_BUSINESS_CONTACT_PAGE_TOOLTIP_CLICKED,
                {
                  name: "country",
                }
              )
            }
          >
            <Tooltip
              tooltipId="geo-tooltip"
              title="Country"
              message={
                <Trans id="setup.business-details.change-country">
                  If you wish to change the country where your organisation is
                  based, please contact our support team (help@gocardless.com)
                </Trans>
              }
            >
              Country
            </Tooltip>
          </Text>
        </Box>

        <Select id="geo" disabled {...register("geo")}>
          {options}
        </Select>
      </Field>
      <Space v={2} />
    </>
  ) : null;
};

export const config: Config = {
  name: "geo",
  required: true,
  displayName: "Country",
  component: CountryField,
};

export default CountryField;
