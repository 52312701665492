import {
  AlignItems,
  Box,
  FontWeight,
  Glyph,
  HoverEffect,
  Icon,
  Interpose,
  PlainButton,
  PlainLink,
  Separator,
  Space,
  Text,
  Visibility,
} from "@gocardless/flux-react";
import { logout } from "src/common/authorisation";
import { Trans } from "@lingui/macro";
import { usePrimaryCreditor } from "src/queries/creditor";
import { ToTranslate } from "src/components/i18n";

const Footer: React.FC = () => {
  const gcSegregationEntity = usePrimaryCreditor()?.gc_segregation_entity;

  const signOutButton = (
    <PlainButton onClick={() => logout()} effect={HoverEffect.TextDecoration}>
      <Box layout="flex" alignItems={AlignItems.Center}>
        <Icon name={Glyph.User} size="12px" />
        <Space layout="inline" h={0.5} />
        <Text weight={FontWeight.Bold}>
          <Trans id="footer.logout">Sign out and continue later</Trans>
        </Text>
      </Box>
    </PlainButton>
  );
  return (
    <Box fontSize={2}>
      <Box spaceAbove={[3, 6]} spaceBelow={[3, 6]}>
        <Separator />
      </Box>
      <Box maxWidth="800px" spaceBelow={2}>
        <Text>
          {gcSegregationEntity && gcSegregationEntity === "gc_inc" ? (
            <ToTranslate>
              GoCardless Inc., 111 Congress Avenue, Suite 500, Austin, TX 78701.
              GoCardless Inc. (a Delaware Corporation, NMLS ID 2123932), is a
              FinCEN-registered money services business,registration number
              31000261158426, and a licensed money transmitter in certain U.S.
              states. You can find further information about GoCardless Inc.‘s
              U.S. licenses and regulators’ contact information{" "}
              <PlainLink
                effect={HoverEffect.TextDecoration}
                textDecoration="underline"
                target="_blank"
                href="https://gocardless.com/en-us/legal/usa-licenses/"
              >
                here
              </PlainLink>
              . GoCardless personal data as described in our{" "}
              <PlainLink
                effect={HoverEffect.TextDecoration}
                textDecoration="underline"
                target="_blank"
                href="https://gocardless.com/privacy/"
              >
                Privacy Notice
              </PlainLink>
              .
            </ToTranslate>
          ) : (
            <Trans id="footer.copyright-authorised">
              © GoCardless Ltd. is authorised by the Financial Conduct
              Authority under the Payment Services Regulations 2009,
              registration number 597190, for the provision of payment services.
            </Trans>
          )}
        </Text>
      </Box>
      <Box layout="flex" flexWrap="wrap">
        <Interpose
          node={
            <Space v={[1.5, null]} layout={["block", "inline"]} h={[null, 2]} />
          }
        >
          <PlainLink
            href="https://hub.gocardless.com/"
            target="_blank"
            effect={HoverEffect.TextDecoration}
          >
            <Text weight={FontWeight.Bold}>
              <Trans id="Help">Help</Trans>
            </Text>
          </PlainLink>
          <PlainLink
            href="https://manage.gocardless.com/"
            target="_blank"
            effect={HoverEffect.TextDecoration}
          >
            <Text weight={FontWeight.Bold}>
              <Trans id="footer.dashboard">GoCardless dashboard</Trans>
            </Text>
          </PlainLink>
          <PlainLink
            href="https://gocardless.com/legal/"
            target="_blank"
            effect={HoverEffect.TextDecoration}
          >
            <Text weight={FontWeight.Bold}>
              <Trans id="footer.terms">Terms</Trans>
            </Text>
          </PlainLink>
          <PlainLink
            href="https://gocardless.com/privacy/merchants"
            target="_blank"
            effect={HoverEffect.TextDecoration}
          >
            <Text weight={FontWeight.Bold}>
              <Trans id="footer.privacy-notice">Privacy notice</Trans>
            </Text>
          </PlainLink>
          <Box layout="inline-block">
            <Visibility visible={["none", "inline-block"]}>
              <Box layout="inline-block" spaceAfter={2}>
                <Separator direction="inline-vertical" />
              </Box>
              {signOutButton}
            </Visibility>
            <Visibility visible={["inline-block", "none"]}>
              {signOutButton}
            </Visibility>
          </Box>
        </Interpose>
      </Box>
      <Space v={[3, 6]} />
    </Box>
  );
};

export default Footer;
