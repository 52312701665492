import { useState } from "react";
import { Box, Interpose, Space } from "@gocardless/flux-react";
import {
  CharityType,
  Currency,
  HomeSchemeEnum,
  SchemeIdentifier,
} from "@gocardless/api/dashboard/types";
import { renderFields } from "src/components/routes/Setup/common/config/utils";
import {
  Field as FieldType,
  FieldAttributes,
} from "src/components/routes/Setup/common/config/types";
import { useFormContext } from "react-hook-form";
import { useOrganisation } from "src/queries/organisation";

import { CharityAddressInput } from "../common/components/Address/AddressInput";

import UnsupportedCreditorType from "./UnsupportedCreditorType";
import { BusinessDetailsConfig } from "./types";

interface CharityBusinessDetailsFormProps {
  fields: FieldType[];
  values: BusinessDetailsConfig;
}

const restrictedSchemes: (HomeSchemeEnum | undefined)[] = [
  HomeSchemeEnum.Ach,
  HomeSchemeEnum.Autogiro,
  HomeSchemeEnum.Betalingsservice,
];

/**
 * To verify as a charity IPFX payments have
 * to be disabled and ACH, Autogiro & Betalingservice
 * schemes removed.
 *
 * The warning shows to inform the merchant
 * to contact support if a change needs to
 * be made.
 *
 * This should only need to happen if they
 * have previously submitted details for a
 * different creditor type
 *
 * @param fx_payout_currency
 * @param scheme_identifiers
 * @returns boolean
 */
export const isCharityRestricted = (
  fx_payout_currency: Currency | null | undefined,
  scheme_identifiers: SchemeIdentifier[] | undefined
): boolean =>
  fx_payout_currency === null &&
  !scheme_identifiers?.find((f) => restrictedSchemes.includes(f.scheme));

const CharityBusinessDetailsForm = ({
  values,
  fields,
}: CharityBusinessDetailsFormProps) => {
  const { fx_payout_currency } = useOrganisation() || {};
  const { geo, scheme_identifiers, charity_type } = values;
  const enabled = isCharityRestricted(fx_payout_currency, scheme_identifiers);

  const [charityType, setCharityType] = useState(
    charity_type ?? CharityType.RegisteredCharity
  );

  const { watch } = useFormContext();
  const creditorType = watch("creditor_type");

  const fieldAttributes: FieldAttributes = {
    geo: {},
    creditor_type: {},
    charity_type: { props: { geo, setCharityType } },
    charity_number: {
      canRender: charityType === CharityType.RegisteredCharity,
    },
    charity_name: {},
    trading_name: {},
    registered_address: {
      component: CharityAddressInput,
      props: {
        address: values,
        countryCode: geo,
      },
    },
    tax_jurisdiction: { props: { countryCode: geo } },
    tax_number: {},
  };

  return (
    <Box>
      <Interpose node={<Space v={2} />}>
        {enabled ? (
          renderFields(fields, fieldAttributes)
        ) : geo && creditorType ? (
          <UnsupportedCreditorType geo={geo} creditorType={creditorType} />
        ) : null}
      </Interpose>
    </Box>
  );
};

export default CharityBusinessDetailsForm;
