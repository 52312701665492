import { useState } from "react";
import _ from "lodash";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Field, Hint, Input, Label } from "@gocardless/flux-react";
import { Controller, useFormContext } from "react-hook-form";
import { checkVAT, countries } from "jsvat-next";

import { Field as Config } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

import { error } from "./helpers";

const VATNumberField = (): JSX.Element => {
  const { i18n } = useLingui();
  const {
    formState: { errors },
    getValues,
  } = useFormContext<BusinessDetailsConfig>();
  const [geo] = useState(getValues()?.geo);
  const isGB = geo === "GB";
  const taxNumberErrorMessages = {
    invalidVAT: i18n._(
      t({
        id: "setup.business-details.tax-number-valid-error-gb",
        message:
          "Please enter a valid VAT registration number. This is 9 numbers, sometimes with 'GB' at the start, for example GB000472631",
      })
    ),
    invalidAny: i18n._(
      t({
        id: "setup.business-details.tax-number-valid-error",
        message: "Please enter a valid VAT registration number.",
      })
    ),
  };

  const taxNumberIsValid = (v: string) => {
    const { isValid } = checkVAT(v, countries);
    if (_.isEmpty(v) || isValid) {
      return true;
    }

    return isGB
      ? taxNumberErrorMessages.invalidVAT
      : taxNumberErrorMessages.invalidAny;
  };

  return (
    <Controller
      name="tax_number"
      rules={{
        validate: taxNumberIsValid,
      }}
      render={({ field }) => (
        <Field>
          <Label htmlFor="tax_number">
            <Trans id="setup.business-details.tax-number">
              VAT registration number (optional)
            </Trans>
          </Label>
          <Hint>
            <Trans id="setup.business-details.tax-number-hint">
              Leave this blank if you are not VAT registered
            </Trans>
          </Hint>
          <Input
            {...field}
            id="tax_number"
            className="fs-exclude"
            value={field.value}
            defaultValue={field.value}
          />
          {error(errors, "tax_number")}
        </Field>
      )}
    />
  );
};

const TVANumberField: React.FC = () => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
  } = useFormContext<BusinessDetailsConfig>();

  return (
    <Field>
      <Label htmlFor="tax_number">
        <Trans id="setup.business-details.tva-number">
          TVA number (optional)
        </Trans>
      </Label>
      <Hint>
        <Trans id="setup.business-details.tva-number-hint">
          Leave this blank if you are not TVA registered
        </Trans>
      </Hint>
      <Input
        {...register("tax_number", {
          validate: (v) => {
            const { isValid } = checkVAT(v ?? "", countries);
            return !(_.isEmpty(v) || isValid)
              ? i18n._(
                  t({
                    id: "setup.business-details.tva-number-valid-error",
                    message: "Please enter a valid TVA number.",
                  })
                )
              : true;
          },
        })}
        id="tax_number"
        className="fs-exclude"
      />
      {error(errors, "tax_number")}
    </Field>
  );
};

export const config: Config = {
  name: "tax_number",
  displayName: (
    <Trans id="setup.business-details.tax-number">
      VAT registration number (optional)
    </Trans>
  ),
  component: VATNumberField,
};

export const FRConfig: Config = {
  name: "tax_number",
  displayName: (
    <Trans id="setup.business-details.tva-number">TVA number (optional)</Trans>
  ),
  component: TVANumberField,
};

export default VATNumberField;
