import { Field, Label, Input } from "@gocardless/flux-react";
import { useFormContext } from "react-hook-form";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";

import { Field as Config } from "../config/types";
import { BusinessDetailsConfig } from "../../business-details/types";

import { error, presenceCheck } from "./helpers";

const CharityNumberField = () => {
  const { i18n } = useLingui();
  const {
    register,
    formState: { errors },
  } = useFormContext<BusinessDetailsConfig>();
  return (
    <Field>
      <Label htmlFor="charity_number">
        <Trans id="charity_number">Charity number</Trans>
      </Label>
      <Input
        id="charity_number"
        {...register(
          "charity_number",
          presenceCheck(
            i18n._(
              t({
                id: "setup.business-details.charity-number-error",
                message: "Charity number is required",
              })
            )
          )
        )}
      />
      {error(errors, "charity_number")}
    </Field>
  );
};

export const config: Config = {
  name: "charity_number",
  displayName: "Charity number",
  component: CharityNumberField,
};

export default CharityNumberField;
