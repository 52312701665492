import { CreditorType } from "@gocardless/api/dashboard/types";
import _ from "lodash";

import { FIELD_CONFIG_AU } from "./AU/";
import { FIELD_CONFIG_GB } from "./GB/";
import { FIELD_CONFIG_GB_CA } from "./GB_CA";
import { FIELD_CONFIG_GB_CH } from "./GB_CH/";
import { FIELD_CONFIG_GB_NZ } from "./GB_NZ/";
import { FIELD_CONFIG_GB_ZA } from "./GB_ZA";
import { FIELD_CONFIG_SEPA } from "./SEPA";
import { FIELD_CONFIG_SEPA_DE } from "./SEPA_DE";
import { FIELD_CONFIG_SEPA_ES } from "./SEPA_ES";
import { FIELD_CONFIG_SEPA_FR } from "./SEPA_FR";
import { FIELD_CONFIG_SEPA_IE } from "./SEPA_IE";
import { FIELD_CONFIG_US } from "./US/";
import { Field, FieldConfig, SetupPages } from "./types";

const GEOS_MAPPING: { [key: string]: FieldConfig } = {
  AT: FIELD_CONFIG_SEPA,
  AU: FIELD_CONFIG_AU,
  BE: FIELD_CONFIG_SEPA,
  BG: FIELD_CONFIG_SEPA,
  CA: FIELD_CONFIG_GB_CA,
  CH: FIELD_CONFIG_GB_CH,
  CY: FIELD_CONFIG_SEPA,
  CZ: FIELD_CONFIG_SEPA,
  DE: FIELD_CONFIG_SEPA_DE,
  DK: FIELD_CONFIG_SEPA,
  EE: FIELD_CONFIG_SEPA,
  ES: FIELD_CONFIG_SEPA_ES,
  FI: FIELD_CONFIG_SEPA,
  FR: FIELD_CONFIG_SEPA_FR,
  GB: FIELD_CONFIG_GB,
  HR: FIELD_CONFIG_SEPA,
  HU: FIELD_CONFIG_SEPA,
  IE: FIELD_CONFIG_SEPA_IE,
  IT: FIELD_CONFIG_SEPA,
  LU: FIELD_CONFIG_SEPA,
  MT: FIELD_CONFIG_SEPA,
  NL: FIELD_CONFIG_SEPA,
  NO: FIELD_CONFIG_SEPA,
  NZ: FIELD_CONFIG_GB_NZ,
  PL: FIELD_CONFIG_SEPA,
  PT: FIELD_CONFIG_SEPA,
  RO: FIELD_CONFIG_SEPA,
  SE: FIELD_CONFIG_SEPA,
  SI: FIELD_CONFIG_SEPA,
  SK: FIELD_CONFIG_SEPA,
  US: FIELD_CONFIG_US,
  ZA: FIELD_CONFIG_GB_ZA,
};

const getAttrs = (fields: Field[]): string[] =>
  fields.reduce(
    (attrs: string[], field) => [
      ...attrs,
      ...(_.isArray(field.component)
        ? getAttrs(field.component)
        : [field.name]),
    ],
    []
  );

const findField = (fields: Field[], fieldName: string): Field | undefined => {
  const foundField = fields.find((field) => field.name === fieldName);
  if (foundField) return foundField;
  return fields
    .filter((field) => _.isArray(field.component))
    .map((field) => findField(field.component as Field[], fieldName))
    .find((field) => !!field);
};

export const useConfig = (page: SetupPages) => {
  /**
   * Gets the page config for the given geo and
   * creditor type and returns it as a flattened
   * array
   *
   * @param geo
   * @param creditorType
   * @returns Field[]
   */
  const get = (
    geo: string | undefined,
    creditorType: CreditorType | undefined | null
  ): Field[] =>
    geo
      ? _.flatten(
          _.get(GEOS_MAPPING[geo], `${creditorType}.${page}`, []).map(
            (i: Field[] | Field) => (Array.isArray(i) ? i : [i])
          )
        ) || []
      : [];

  /**
   * Public method exposing get
   *
   * We use this additional method on top of get to apply
   * any specific feature flag configuration rules
   * that should apply.
   *
   * Currently charities are enabled with trusts and
   * partnerships coming online within GB, ANZ & US
   * where applicable in Q1 2023.
   *
   * @param creditorType
   * @param geo
   * @returns Field[]
   */
  const getFieldsByCreditorTypeAndGeo = (
    creditorType: CreditorType | undefined | null,
    geo: string | undefined
  ): Field[] => get(geo, creditorType);

  /**
   * Looks into the config and returns the attrs required for
   * a given creditor type and geo as a flat array
   *
   * @param creditorType
   * @param geo
   * @returns string[]
   */
  const getAttrsByCreditorTypeAndGeo = (
    creditorType: CreditorType | undefined | null,
    geo: string | undefined | null
  ) =>
    geo
      ? ["name", ...getAttrs(getFieldsByCreditorTypeAndGeo(creditorType, geo))]
      : [];

  /**
   * Searches config to find a specific field or
   * field group based on geo and creditor type
   *
   * @param name
   * @param geo
   * @param creditorType
   * @returns Field[]
   */
  const getSubFields = (
    name: string,
    geo: string,
    creditorType: CreditorType | undefined | null
  ): Field[] => {
    const foundField = findField(
      getFieldsByCreditorTypeAndGeo(creditorType, geo),
      name
    );
    return !foundField || !foundField.component
      ? []
      : _.isArray(foundField.component)
        ? foundField.component
        : [foundField?.component];
  };

  return {
    getFieldsByCreditorTypeAndGeo,
    getAttrsByCreditorTypeAndGeo,
    getSubFields,
  };
};
