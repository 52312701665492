import {
  RequiredActionConfirmDepositResponseBody,
  useRequiredActionList,
} from "@gocardless/api/dashboard/required-action";
import {
  RequiredActionResource,
  RequiredActionsName,
  RequiredActionsStatus,
} from "@gocardless/api/dashboard/types";
import { useConfirmDeposit } from "src/common/hooks/requiredActions";
import { Route } from "src/common/routing";
import { useBankDetails } from "src/components/routes/Setup/bank-details/hooks/useBankDetails";
import { UseSetupPage } from "src/components/routes/Setup/routing/types";

export interface UseVerifyBank extends UseSetupPage {
  requiredActions?: RequiredActionResource[];
  confirmDeposit: (
    creditorId: string,
    verificationId: string
  ) => Promise<RequiredActionConfirmDepositResponseBody>;
  refreshSetup: () => Promise<boolean>;
}

export const useVerifyBank = (): UseVerifyBank => {
  const { data, revalidate: refreshSetup } = useRequiredActionList();
  const { completed: bankDetailsCompleted } = useBankDetails();
  const confirmDeposit = useConfirmDeposit();

  const depositVerifications = data?.required_actions?.filter(
    (action) => action.name === RequiredActionsName.SendVerificationDeposit
  );

  // completed if all required verifications are successful or deposit confirmed
  const completed =
    !!depositVerifications?.length &&
    depositVerifications?.every(
      (action) =>
        action.entity?.deposit_confirmed ||
        action.status === RequiredActionsStatus.Successful
    );

  // skip if bank details completed, but no deposit verifications available.
  const skip = bankDetailsCompleted && depositVerifications?.length === 0;

  return {
    loaded: !!data?.required_actions,
    requiredActions: data?.required_actions,
    route: Route.VerifyBank,
    completed,
    confirmDeposit,
    refreshSetup,
    skip,
  };
};
