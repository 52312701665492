import {
  Box,
  Button,
  ButtonVariant,
  Field,
  FontWeight,
  Interpose,
  JustifyContent,
  Label,
  Space,
  Text,
  TextAlign,
} from "@gocardless/flux-react";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useEffect,
} from "react";
import _, { pick } from "lodash";
import { useRegisteredCompanyLookupCreate } from "@gocardless/api/dashboard/registered-company-lookup";
import { useFormContext } from "react-hook-form";
import FormCard from "src/components/ui/form-card/FormCard";
import {
  Field as FieldType,
  FieldAttributes,
  SetupPages,
} from "src/components/routes/Setup/common/config/types";
import { renderFields } from "src/components/routes/Setup/common/config/utils";
import { HTTPError } from "@gocardless/api/utils/api";
import { errorNotificationHandler } from "src/common/notifications/notificationErrorHandlers";
import { useToastNotification } from "src/common/notifications/useToastNotification";

import SearchInput from "../common/components/SearchInput";
import { MerchantOnboardingSetupEvents } from "../common/constants/MerchantOnboardingSetupEvents";
import { useComplete } from "../common/validators/useComplete";
import { flattenFieldArray } from "../common/fields/helpers";
import { useSegmentForSetup } from "../common/hooks/useSegmentForSetup";

import CompanyDetails from "./CompanyDetails";
import { BusinessDetailsConfig, CompanyDetailsConfig } from "./types";
import { useRegisteredCompanies } from "./useRegisteredCompanies";

interface CompanyBusinessDetailsFormProps {
  fields: FieldType[];
  values: BusinessDetailsConfig;
  enterCompanyDetailsManually: boolean;
  setEnterCompanyDetailsManually: Dispatch<SetStateAction<boolean>>;
  companySearchCompleted: boolean;
  setCompanySearchCompleted: Dispatch<SetStateAction<boolean>>;
  setRegisteredCompanySearchId: Dispatch<SetStateAction<string | null>>;
}

const CompanyBusinessDetailsForm = ({
  fields,
  values,
  enterCompanyDetailsManually,
  setEnterCompanyDetailsManually,
  companySearchCompleted,
  setCompanySearchCompleted,
  setRegisteredCompanySearchId,
}: CompanyBusinessDetailsFormProps): JSX.Element => {
  const { sendEvent } = useSegmentForSetup();
  const { register, watch, setValue } = useFormContext();
  const { i18n } = useLingui();
  const { triggerErrorNotification } = useToastNotification();
  const { isComplete } = useComplete(SetupPages.BUSINESS_DETAILS);

  const { geo } = values;
  const creditor_type = watch("creditor_type");

  const [companyDetails, setCompanyDetails] = useState<CompanyDetailsConfig>(
    pick(values, [
      "legal_name",
      "trading_name",
      "company_number",
      "address_line1",
      "address_line2",
      "address_line3",
      "city",
      "region",
      "postal_code",
      "tax_jurisdiction",
    ])
  );

  useEffect(() => {
    setValue("company_number", companyDetails.company_number);
  }, [setValue, companyDetails]);

  useEffect(() => {
    if (companySearchCompleted) {
      setValue("tax_number", undefined);
      setValue("registeredCompaniesQuery", undefined);
    }
  }, [companySearchCompleted, setValue]);

  const isPartiallyComplete = companyDetails.company_number
    ? !isComplete({
        geo,
        creditor_type,
        ...companyDetails,
      })
    : false;

  const [registeredCompaniesQuery, setRegisteredCompaniesQuery] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetRegisteredCompaniesQuery = useCallback(
    _.debounce(setRegisteredCompaniesQuery, 300),
    [setRegisteredCompaniesQuery]
  );

  const { registeredCompanies, registeredCompaniesComplete } =
    useRegisteredCompanies(registeredCompaniesQuery, geo);

  const fieldAttributes: FieldAttributes = {
    legal_name: { props: { defaultValue: companyDetails.legal_name } },
    trading_name: { props: { defaultValue: companyDetails.trading_name } },
    company_number: { props: { defaultValue: companyDetails.company_number } },
    address_line1: { props: { defaultValue: companyDetails.address_line1 } },
    address_line2: { props: { defaultValue: companyDetails.address_line2 } },
    address_line3: { props: { defaultValue: companyDetails.address_line3 } },
    city: { props: { defaultValue: companyDetails.city } },
    region: { props: { defaultValue: companyDetails.region } },
    postal_code: {
      props: {
        countryCode: geo,
        defaultValue: companyDetails.postal_code,
      },
    },
    tax_jurisdiction: {
      props: {
        countryCode: geo,
        defaultValue: companyDetails.tax_jurisdiction,
      },
    },
  };

  const allFields = flattenFieldArray(fields);

  const isTaxJurisdictionRequired = !!allFields.find(
    (field) => field.name === "tax_jurisdiction"
  );

  const handleError = async (error: HTTPError) => {
    if (error.name === "HTTPError") {
      if (error.stack?.includes("429")) {
        triggerErrorNotification({
          message: i18n._(
            t({
              id: "rate-limit-company-lookups.error",
              message:
                "We couldn't get the company details because of too many attempts, please fill in the details manually or try again later.",
            })
          ),
          duration: 9000,
        });
      } else {
        await errorNotificationHandler(error, triggerErrorNotification);
      }
    }
  };

  const [doLookup] = useRegisteredCompanyLookupCreate({
    onSuccess: (res) => {
      const {
        registered_company_lookups: {
          name = "",
          number = "",
          address: {
            // defaults when address parts are undefined
            address_line1 = "",
            address_line2 = "",
            address_line3 = "",
            city = "",
            region = "",
            postal_code = "",
          } = {},
          country_code = "",
        } = {},
      } = res || {};

      const lookupResult: CompanyDetailsConfig = {
        legal_name: name,
        trading_name: companyDetails.trading_name,
        company_number: number,
        // defaults when address parts are null
        address_line1: address_line1 || "",
        address_line2: address_line2 || "",
        address_line3: address_line3 || "",
        city: city || "",
        region: region || "",
        postal_code: postal_code || "",
        tax_jurisdiction: isTaxJurisdictionRequired ? country_code || "" : "",
      };

      sendEvent(
        MerchantOnboardingSetupEvents.BusinessInformationExistingCompanySelected
      );

      const isDataIncomplete = !isComplete({
        geo,
        creditor_type,
        ...lookupResult,
      });

      setCompanySearchCompleted(true);
      setCompanyDetails(lookupResult);
      setEnterCompanyDetailsManually(isDataIncomplete);
    },
    onError: async (e) => {
      setCompanySearchCompleted(true);
      setEnterCompanyDetailsManually(true);
      setCompanyDetails({});
      setRegisteredCompanySearchId(null);
      await handleError(e as HTTPError);
    },
  });

  const companyDetailsManual = (hidden: boolean) => (
    <Box css={{ display: hidden ? "none" : "?" }}>
      <Interpose node={<Space v={2} />}>
        {renderFields(allFields, fieldAttributes)}
      </Interpose>
    </Box>
  );

  if (!companySearchCompleted) {
    return (
      <>
        {companyDetailsManual(true)}
        <Field>
          <Label htmlFor="registeredCompaniesQuery">
            <Trans id="setup.business-details.search-for-company">
              Search for a company
            </Trans>
          </Label>
          <SearchInput<{
            legalName: string;
            companyNumber: string;
            geo: string;
          }>
            id="registeredCompaniesQuery"
            placeholder={i18n._(
              t({
                id: "setup.business-details.start-typing-and-select-company",
                message: "Start typing and select company",
              })
            )}
            data-testid="registeredCompaniesQuery"
            searchResults={registeredCompanies.map((registeredCompany) => ({
              key: registeredCompany.companyNumber as string,
              ...registeredCompany,
            }))}
            searchComplete={registeredCompaniesComplete}
            renderSearchResult={({ legalName, companyNumber }) => (
              <Box
                layout="flex"
                justifyContent={JustifyContent.SpaceBetween}
                gutterH={0.75}
                gutterV={0.75}
              >
                <Text
                  textAlign={TextAlign.Left}
                  className="fs-mask"
                  weight={FontWeight.Normal}
                >
                  {legalName}
                </Text>
                <Text
                  fontFamily="monospace"
                  className="fs-mask"
                  weight={FontWeight.Normal}
                >
                  {companyNumber}
                </Text>
              </Box>
            )}
            renderEnterManually={() => (
              <>
                <Trans id="setup.business-details.cant-find-company">
                  Can&apos;t find your company?
                </Trans>{" "}
                <Button
                  variant={ButtonVariant.InlineUnderlined}
                  onClick={() => {
                    sendEvent(
                      MerchantOnboardingSetupEvents.BusinessInformationManuallyEnterDetailsSelected
                    );
                    setCompanySearchCompleted(true);
                    setEnterCompanyDetailsManually(true);
                    setRegisteredCompanySearchId(null);
                  }}
                >
                  <Text weight={FontWeight.Bold}>
                    <Trans id="setup.business-details.enter-details-manually">
                      Enter your details manually
                    </Trans>
                  </Text>
                </Button>
              </>
            )}
            onSearchResultSelected={({
              companyNumber,
              geo: selectedGeo,
            }: {
              companyNumber: string;
              geo: string;
            }) => {
              setRegisteredCompanySearchId(companyNumber);
              doLookup({
                number: companyNumber,
                country_code: selectedGeo,
              });
            }}
            {...register("registeredCompaniesQuery", {
              required: i18n._(
                t({
                  id: "setup.business-details.search-required-error",
                  message:
                    "Please search for your company or enter your details manually",
                })
              ),
            })}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              event.preventDefault();
              debounceSetRegisteredCompaniesQuery(event.target.value);
            }}
            className="fs-exclude"
          />
        </Field>
      </>
    );
  }

  return (
    <Box spaceAbove={2}>
      <Label>
        <Trans id="setup.business-details.double-check">
          Double check your business details and edit if necessary
        </Trans>
      </Label>
      <Space v={1} />
      <FormCard
        heading={i18n._(
          t({
            id: "setup.business-details.heading",
            message: "Business Details",
          })
        )}
        initialIsOpen={enterCompanyDetailsManually || isPartiallyComplete}
        onEdit={() => {
          setEnterCompanyDetailsManually(true);
          setRegisteredCompanySearchId(null);
          sendEvent(
            MerchantOnboardingSetupEvents.BusinessInformationEditClicked
          );
        }}
      >
        {({ isOpen }) =>
          !isOpen ? (
            <>
              {companyDetailsManual(true)}
              <CompanyDetails data={companyDetails} fields={fields} />
            </>
          ) : (
            companyDetailsManual(false)
          )
        }
      </FormCard>
      {companySearchCompleted && (
        <>
          <Space v={2} />
          <Button
            variant={ButtonVariant.InlineUnderlined}
            onClick={() => {
              setCompanySearchCompleted(false);
              setEnterCompanyDetailsManually(false);
              setRegisteredCompanySearchId(null);
            }}
          >
            <Trans id="setup.business-details.company-search-again">
              Search for company again
            </Trans>
          </Button>
        </>
      )}
      <Space v={1} />
    </Box>
  );
};

export default CompanyBusinessDetailsForm;
