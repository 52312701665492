import _ from "lodash";
import { SetupPages } from "src/components/routes/Setup/common/config/types";
import {
  ControlProngType,
  CreditorType,
  TrusteeType,
} from "@gocardless/api/dashboard/types";

import { useConfig } from "../config/useConfig";
import { Field } from "../config/types";

export interface ValidatorData {
  creditor_type?: CreditorType | null | undefined;
  geo?: string | null;
  country_code?: string | null;
  type?: TrusteeType | ControlProngType;
  partnership_type?: string;
}

export const useComplete = (page: SetupPages) => {
  const { getFieldsByCreditorTypeAndGeo } = useConfig(page);
  /**
   * Recursively traverses through field configuration
   * returning a flat array of all fields in a given
   * configuration
   */
  function recurse(fields: Field[], result: Field[]) {
    fields.forEach((i) => {
      typeof i.component === "function"
        ? result.push(i)
        : recurse(i.component as Field[], result);
    });
    return result;
  }

  /**
   * Determines whether a section/page is complete by
   * evaluating data returned from the API against the
   * required fields on a given page
   */
  const isComplete = (data: ValidatorData, excludedFields?: string[]) => {
    const { creditor_type, geo } = data;
    if (!creditor_type || !geo) return false;

    let fields = getFieldsByCreditorTypeAndGeo(creditor_type, geo);

    // Clean up excludedFields to ignore falsy strings
    const validExcludedFields = excludedFields?.filter((field) => field) || [];

    if (validExcludedFields.length > 0) {
      fields = fields.filter((f) => !validExcludedFields.includes(f.name));
    }

    return recurse(fields, [])
      .filter((f) =>
        typeof f.required === "function" ? f.required(data) : f.required
      )
      .map(
        (i) =>
          !!Object.values(_.pick(data, [i.name]))
            .pop()
            ?.trim()
      )
      .every((i) => i);
  };

  /**
   * Determines whether a section/page is complete by evaluating
   * a list of data returned from the API against the required
   * fields on a given page. If a single entry is true it is complete
   */
  const isListComplete = (creditorType: CreditorType, data: ValidatorData[]) =>
    data
      .map((i) =>
        isComplete({
          geo: i.country_code,
          creditor_type: creditorType,
          ...i,
        })
      )
      .includes(true);

  return {
    isComplete,
    isListComplete,
  };
};
